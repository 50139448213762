var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{class:{'elevation-1': true, 'row-pointer': _vm.options.rowClick},attrs:{"headers":_vm.headers,"items":_vm.data.results,"loading":_vm.loading,"hide-default-footer":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("default")]},proxy:true},_vm._l((_vm.itemKeys),function(itemKey,index){return {key:("item." + itemKey),fn:function(ref){
var item = ref.item;
return [_c('div',{key:index},[_vm._v(_vm._s(item[itemKey] == null ? 'no data' : item[itemKey]))])]}}}),_vm._l((_vm.options.avatar),function(avatar,index){return {key:("item." + avatar),fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{key:index},[_c('img',{attrs:{"src":item[avatar]}})])]}}}),_vm._l((_vm.options.timestamps),function(timestamp,index){return {key:("item." + timestamp),fn:function(ref){
var item = ref.item;
return [(item[timestamp] == null)?_c('div',{key:index},[_vm._v("No data")]):_c('div',{key:index},[_vm._v(_vm._s(_vm.moment(item[timestamp]).format("YYYY-MM-DD HH:mm:ss")))])]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.options.actions),function(action,index){return _c('v-btn',{key:index,staticClass:"mr-1",attrs:{"color":action.color,"dark":"","rounded":"","small":"","icon":"","href":item[action.hrefName],"target":"_blank"}},[_c('v-icon',[_vm._v(" "+_vm._s(action.icon)+" ")])],1)})}},_vm._l((_vm.options.statusChips),function(statusChip,index){return {key:("item." + statusChip),fn:function(ref){
var item = ref.item;
return [_c('v-chip',{key:index,staticClass:"status-chip status-pill rounded-pill white--text font-weight-black",attrs:{"color":_vm.getColor(item[statusChip]),"dark":"","medium":""}},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item[statusChip])+" ")])])]}}}),_vm._l((_vm.options.contentGradeChips),function(contentGradeChip,index){return {key:("item." + contentGradeChip),fn:function(ref){
var item = ref.item;
return [_c('v-chip',{key:index,attrs:{"color":_vm.getContentGradeColor(item[contentGradeChip]),"text-color":_vm.getTextContentGradeColor(item[contentGradeChip]),"dark":"","medium":""}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"min-width":"70px"}},[_vm._v(" "+_vm._s(item[contentGradeChip])+" / 10 ")])])]}}})],null,true)}),(_vm.paginate)?_c('DataTableFooter',{attrs:{"data":_vm.data,"paginate":_vm.paginate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }